import Vue from 'vue';
import Vuex from 'vuex';
import StoreData from './store';
import QuickSearchOpener from './vue/components/QuickSearchOpener.vue';
import QuickSearch from './vue/components/QuickSearch.vue';

window.Vue = Vue;

Vue.use(Vuex);
const store = new Vuex.Store(StoreData);

require('./bootstrap');

Vue.component('quicksearch-opener', QuickSearchOpener);
Vue.component('quicksearch', QuickSearch);

var app = new Vue({
    el: '#app',
    store: store,
});
